@import '../../styles/variables';

.col-two-module {
    margin: 1.6rem;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: auto;

    @media print {
	grid-template-columns: repeat(24, 11mm);  
	page-break-after: always;
    }
	
	@media only screen and (min-width: $bp-medium){
	    margin: 3.2rem;
	}
	@media only screen and (min-width: $bp-largest){
	    margin: 4.8rem auto 0 auto;
	    max-width: 200rem;
	}

    &__head {
	grid-column: 1/13;
	grid-row: 1/2;
	margin-bottom: $margin-base;
	
	@media print {
	    grid-column: 1/25;
	}
    }

    &__col {
	grid-column: 1/13;
	grid-row: auto;
	    @media only screen and (min-width: $bp-xlarge){
	       grid-column: 2/12; 
	    }
	    @media print {
		grid-column: 1/25;
	    }

	&--first {
	    @media print {
		grid-column:1/12;
	    }
	    @media only screen and (min-width: $bp-large){
	       grid-column: 1/7; 
	    padding-right: 3.2em;
	    }
	    @media only screen and (min-width: $bp-xlarge){
	       grid-column: 2/7; 
	    }
	}

	&--second {
	    @media print {
		grid-column:13/25;
	    }
	    @media only screen and (min-width: $bp-large){
		grid-column:7/13;
		padding-left: 3.2em;
	    }
	    @media only screen and (min-width: $bp-xlarge){
	       grid-column: 7/12; 
	    }
	}

	.role {
	    background: $color-pri;
	    display: inline-block;
	    width: 100%;
	    line-height: 5rem;
	    font-weight: bold;
	    color: $main;
	    text-align: center;
	    margin-bottom: 1.5rem;
	}

    }
    
    &--synopsis {
	color: $color-pri;
    }

    h3 {
	margin-bottom: 3rem;
    }

    img {
	width:100%;
    }

    ol, ul {
	b,li::marker  {
	    color: $color-pri;
	    font-weight: 700;
	}
    }

}
