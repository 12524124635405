@import '../styles/variables';

body {
    font-family: $font-sec;
    font-weight: 300;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-family: $font-pri;
    margin-bottom: $margin-base;
    margin-top: $margin-base * 3;
    page-break-after: avoid;
    page-break-before: avoid;
}


h1,
.h1 {
    color: $color-pri;
    font-size: 2.8rem;
    
    @media only screen and (min-width: $bp-medium){
	font-size:4.8rem;
    }
}

h2,
.h2 {
    color: $color-pri; 
    font-size: 2.8rem;
    font-weight: 700;
    
    @media only screen and (min-width: $bp-medium){
	font-size:4.8rem;
    }
}

h3,
.h3 {
    color: $color-sec;
    font-size: 1.9rem;
    font-weight: normal;
    
    @media only screen and (min-width: $bp-medium){
	font-size:2.4rem;
    }
}

.subheading{
    color: $color-pri;
    font-size: 2.4rem;
}

b {
    font-weight:bold;
}
