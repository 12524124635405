@import '../../styles/variables';

section.button {
   
    @media print {
	    display: none;
    }

    margin: 3.2rem;

    button.print {
        margin: 0 auto;
        text-align: center;
        font-family: $font-pri;
        font-size: 16px;
        color: #dd007d;
        font-weight: 600;
        border: 2px solid #dd007d;
        padding: 15px 20px;
        border-radius: 0;
        display: block;
        max-width: 150px;
        background: transparent;
        cursor: pointer;

        &:hover {
            background: #dd007d;
            color: #ffffff;
        }
    }
}
