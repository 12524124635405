@import '../styles/variables';

@page {
margin: 10mm;
size:landscape;
}


html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px
  font-family: $font-pri;
  @media print {
      font-size: 45%;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-size: 1.6rem;
}

p {
  margin-bottom: $margin-base;
  line-height: 1.5;
  page-break-inside: avoid;
}

ul,ol{
    padding: 0 2rem 0 1rem;
    margin-left: 3rem;
    page-break-inside: avoid;
}

li {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: $margin-base * 2;
}

main {
  margin: 0 auto;
}

strong {
    color: $color-pri;
}

strong.secondary {
    color: $color-sec;
}
