@import '../../styles/variables';

.frontpage {
    margin: 1.6rem;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: auto;
    
    @media print {
	grid-template-columns: repeat(24, 11mm);  
	page-break-after: always;
    }

    @media only screen and (min-width: $bp-medium){
        margin: 3.2rem;
    }

    @media only screen and (min-width: $bp-largest){
        margin: 4.8rem auto 0 auto;
        max-width: 200rem;
    }

    &__logo {
        grid-column: 1/13;
        grid-row: 1/2;
        margin-bottom: $margin-base;
        width: 100%;
        
        @media only screen and (min-width: $bp-small){
           grid-column: 1/5; 
        }
        
        @media only screen and (min-width: $bp-medium){
           grid-column: 1/6; 
           grid-row: 1/5;
        }
        @media only screen and (min-width: $bp-xlarge){
           grid-column: 2/6; 
        }

        @media print {
           grid-column: 2/12;
           grid-row: 1/5;
        }
    }

    &__text {
        grid-column: 1/13;
        grid-row: 2/3;
        margin-bottom: $margin-base;
        
        @media only screen and (min-width: $bp-small){
           grid-column: 6/13; 
           grid-row: 1/2;
        }
        
        @media only screen and (min-width: $bp-medium){
           grid-column: 7/13; 
           grid-row: 2/4;
        }
        @media only screen and (min-width: $bp-xlarge){
           grid-column: 7/12; 
        }
        
        @media print {
           grid-column: 14/24;
           grid-row: 2/4;
        }


        h1 {
            color: $color-sec;
            font-weight: 600;
            font-size: 2rem;
        }

        h2 {
            font-weight: 700;
            margin-top: 1rem;
            font-size: 3rem;
        }
        
        .frontpage__name{
            color: $color-pri;
            font-weight: 700;
        }
    }

}
