@import '../../styles/variables';

.tx-felogin-pi1 {

    @media print {
	    display: none;
    }

    max-width: 50rem;
    margin: 0 auto;

    fieldset{
	margin: 1.6rem;
	padding: 2rem;
    }

    label {
	display: block;
    }

    input {
	width:100%;
	margin-bottom: 2rem;
	&[type=submit]{
	    width: 10rem;
	    margin:0 auto;
	    display: block;
	}
    }
}
