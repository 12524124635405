@import '../../styles/variables';

.role-module {
    margin: 1.6rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;

    @media print {
	grid-template-columns: repeat(24, 11mm);  
	page-break-after: always;
    }
	@media only screen and (min-width: $bp-medium){
	    margin: 3.2rem;
	}
	@media only screen and (min-width: $bp-largest){
	    margin: 0 auto;
	    max-width: 200rem;
	}

	
    
    &__head {
	grid-column: 1/13;
	grid-row: 1/2;
	margin-bottom: $margin-base;
	
	@media only screen and (min-width: $bp-xlarge){
	    grid-column: 2/12;
	}
	.h1 {
	    font-size: 1.7rem;
	}
	@media print {
	    grid-column: 1/25;
	}
    }

    &__symbol {
	grid-column: 1/13;
	grid-row: 2/3;
	
	img {
	    width: 100%;
	}
	    @media print {
		grid-column:1/9;
	    }
	@media only screen and (min-width: $bp-small){
	    grid-column: 1/4;
	}
	@media only screen and (min-width: $bp-medium){
	    grid-column: 1/5;
	}
	@media only screen and (min-width: $bp-xlarge){
	    grid-column: 2/5;
	}
    }

    &__content {
	grid-column: 1/13;
	grid-row: span 3;
	
	    @media print {
		grid-column:12/25;
		grid-row:2/5;
	    }
	@media only screen and (min-width: $bp-medium){
	    grid-column: 6/13;
	}
	@media only screen and (min-width: $bp-xlarge){
	    grid-column: 6/12;
	}
	@media only screen and (min-width: $bp-largest){
	    grid-column: 7/12;
	}
    }

    &__synopsis {
	grid-column: 1/13;
//	grid-row: 2/3;
	color: $color-pri;
	//font-size: 1.5rem;
	
	    @media print {
		grid-column:1/9;
	    }
	@media only screen and (min-width: $bp-xsmall){
	    grid-column: 1/13;
	    grid-row: 3/4;
	}
	@media only screen and (min-width: $bp-small){
	    grid-column: 5/13;
	    grid-row: 2/3;
	}
	@media only screen and (min-width: $bp-medium){
	    grid-column: 1/5;
	    grid-row: 3/4;
	}
	@media only screen and (min-width: $bp-xlarge){
	    grid-column: 2/5;
	}
    }

    &--rtl {


    .role-module__symbol {
	
	img {
	    width: 100%;
	}
	    @media print {
		grid-column:17/25;
	    }
	@media only screen and (min-width: $bp-small){
	    grid-column: 9/13;
	}
	@media only screen and (min-width: $bp-xlarge){
	    grid-column: 9/12;
	}
    }

    .role-module__content {
	
	    @media print {
		grid-column:1/14;
		grid-row:2/5;
	    }
	@media only screen and (min-width: $bp-medium){
	    grid-column: 1/8;
	}
	@media only screen and (min-width: $bp-xlarge){
	    grid-column: 2/8;
	}
	@media only screen and (min-width: $bp-largest){
	    grid-column: 2/7;
	}
    }

    .role-module__synopsis {
	grid-column: 1/13;
	color: $color-pri;
	
	    @media print {
		grid-column:17/25;
	    }
	@media only screen and (min-width: $bp-small){
	    grid-column: 1/9;
	}
	@media only screen and (min-width: $bp-medium){
	    grid-column: 9/13;
	}
	@media only screen and (min-width: $bp-xlarge){
	    grid-column: 9/12;
	}
    }

    }
}
