@import '../../styles/variables';

.imprint {

    .header {
        grid-column: 2/12;
	    margin-bottom: $margin-base;
    }

    .content {
        grid-column: 2/10;

        p {
            margin: 1.5rem 0;
        }
    }
}
