#container {
  overflow: visible !important;
}

.highcharts-exporting-group {
  display: none !important;
}

.highcharts-root {
  overflow: visible;
}

.highcharts-axis-labels span {
  width: 200px !important;
}

.highcharts-container {
  padding: 0 !important;
  overflow: visible !important;
}

.highcharts-title {
  top: -30px !important;
}