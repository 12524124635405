@import '../styles/variables';

.footer-copy {
    text-align: center;
    @media print {
	display: none;
    }
}

// Highcharts Spiderplott

#container {
    page-break-after: avoid;
    max-width:100rem;
    @media print {
        width:260mm;
        max-width: none;
        transform: scale(0.9);
    }
    margin:5rem auto;
    .highcharts-credits,
    .highcharts-axis-title {
	display:none;
    }
    
}

// Logo at Login- and Logoutscreen

.loginlogo {
    margin: 0 auto;
    display: block;
}

// Utilclass to remove elements from print
.noprint {
    @media print {
	    display: none !important;
    }
}
