// Colors

$main: #000;
$color-pri: #00b0f0;
$color-sec: #de007e;

//$hl-color-pri: #7ed9f0;
//$hl-color-sec: #b7f2f3; Nach Konzept-Rücksprache entfernt.

// Breakpoints

$bp-xsmall: 25em;   // 400px
$bp-small: 30em;    // 480px
$bp-medium: 53em;   // 834px
$bp-large: 62em;    // 992px
$bp-xlarge: 75em;   // 1200px
$bp-largest: 100em; // 1600px

// Fonts

$font-pri: 'Source Sans Pro', sans-serif;
$font-sec: 'Source Sans Pro', sans-serif;

// Margins

$margin-base: 1rem;
